<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-tabs content-class="mt-3">
        <b-tab title="Plan Cosecha" active lazy>
          <PlanCosecha :planificationId="planificationId" ></PlanCosecha>
        </b-tab>
        <!--
        <b-tab title="Plan Productivo" lazy>
          <PlanProductivo :planificationId="planificationId" ></PlanProductivo>
        </b-tab>
        -->
        <!--
        <b-tab title="Comercial" lazy>
          <Comercial :planificationId="planificationId"></Comercial>
        </b-tab>
        -->
        <!--
        <b-tab title="Detalle de Jaula" lazy>
          <DetalleJaula :planificationId="planificationId"></DetalleJaula>
        </b-tab>
        -->
      </b-tabs>

      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..." />
          <p id="cancel-label">Cargando Resultados...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>




import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";

import PlanCosecha from "./plan-cosecha/PlanCosecha.vue";
import PlanProductivo from "./plan-productivo/PlanProductivo.vue";
import Comercial from "./comercial/Comercial.vue";
import DetalleJaula from "./detalle-jaula/DetalleJaula.vue"


export default {
  data()
  {
    return {
      showOverlay: false,
      planificationId: null


    };
  },
  created()
  {

    this.planificationId = this.$route.params.planification_id;



  },


  

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    PlanCosecha,
    PlanProductivo,
    Comercial,
    DetalleJaula
  },



};
</script>

<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>

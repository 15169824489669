<template>
  <b-row>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{primaria_propia}}</h2>
          <span>Primaria Propia</span>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{primaria_externa}}</h2>
          <span>Primaria Externa</span>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{secundaria_propia}}</h2>
          <span>Secundaria Propia</span>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{secundaria_externa}}</h2>
          <span>Secundaria Externa</span>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>


import
{
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup,
  BAvatar
} from "bootstrap-vue";

import axios from '@axios' 

export default {

  directives: {
  },

  props: {

    dataUrl: {
      type: String,
      required: true,
    },


  },

  data()
  {
    return {

        primaria_propia: 0,
        primaria_externa: 0,
        secundaria_propia: 0,
        secundaria_externa: 0,


    };
  },


  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BCardTitle,
    BCardSubTitle,
    BAvatar

  },

  created()
  {
    this.getData()
      .then((response) =>
      {

        this.primaria_propia = response.data.primaria_propia
        this.primaria_externa = response.data.primaria_externa
        this.secundaria_propia = response.data.secundaria_propia
        this.secundaria_externa = response.data.secundaria_externa


      })
  },


  methods: {

    getData()
    {
      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })
    },




  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

